import { useSelector } from "react-redux";
import  Link  from "components/layout/navigation/Link";
import { useGetAccountDataQuery } from "services/AccountApi";
import classes from "components/layout/navigation/SideMenu.module.scss"
import {ReactComponent as User} from "assets/user.svg"
import {ReactComponent as ChevronRight} from "assets/chevron-right.svg"
import cx from "classnames";
import SideNavigation from "./SideNavigation";

const SideMenu = () => {

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { data, isLoading } = useGetAccountDataQuery(undefined, {skip: !isAuthenticated} );

  const _classes = cx(classes.card, {});
  

  return (
    <div className={classes.sideMenu}>
        <SideNavigation is_loading={isLoading} className={_classes} />
        <Link to={"/account"} className={classes.user}>
            <span><User /></span>
            <div>
                <div className={classes.userName}>{isLoading ? 'Loading...' :  data?.user?.firstName+' '+data?.user?.lastName} </div>
                <div className={classes.accountText}>View your Account</div>
            </div>
            <div className={classes.chevronRight}>
                <ChevronRight />
            </div>
        </Link>
    </div>
  );
}

SideMenu.displayName = 'SideMenu';
export default SideMenu;
