import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageTitle: "",
  pageClass: "",
  breakPoint: null,
  showSideMenu: false,
  header: {
    show: false,
    logo: false,
    search: false,
    reportItem: false,
    contactItem: false,
    registerButton: false,
    loginButton: false,
    logout: false
  },
  back: null
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setPageClass(state, action) {
      state.pageClass = action.payload;
    },
    setSideMenu(state, action) {
      state.showSideMenu = typeof action.payload !== 'undefined' || action.payload != null ? action.payload : true
    },
    setBackButton(state, action) {
      state.back = typeof action.payload !== 'undefined' || action.payload != null ? action.payload : null
    },
    setUpHeader(state, action) {
      state.back = null;
      if (action.payload) {
        if (typeof action.payload.show !== 'undefined' || action.payload.show != null) {
          state.header.show = action.payload.show
        }
        else {
          state.header.show = true
        }
        if (typeof action.payload.logo !== 'undefined' || action.payload.logo != null) {
          state.header.logo = action.payload.logo
        }
        else {
          state.header.logo = true;
        }
        if (typeof action.payload.search !== 'undefined' || action.payload.search != null) {
          state.header.search = action.payload.search
        }
        else {
          state.header.search = true;
        }
        if (typeof action.payload.reportItem !== 'undefined' || action.payload.reportItem != null) {
          state.header.reportItem = action.payload.reportItem
        }
        else {
          state.header.reportItem = true;
        }
        if (typeof action.payload.contactItem !== 'undefined' || action.payload.contactItem != null) {
          state.header.contactItem = action.payload.contactItem
        }
        else {
          state.header.contactItem = true;
        }

        if (typeof action.payload.registerButton !== 'undefined' || action.payload.registerButton != null) {
          state.header.registerButton = action.payload.registerButton
        }
        else {
          state.header.registerButton = false;
        }
        if (typeof action.payload.loginButton !== 'undefined' || action.payload.loginButton != null) {
          state.header.loginButton = action.payload.loginButton
        }
        else {
          state.header.loginButton = null
        }
        if (typeof action.payload.logout !== 'undefined' || action.payload.logout != null) {
          state.header.logout = action.payload.logout
        }
        else {
          state.header.logout = false
        }
      }
      else {
        state.header = {
          show: true,
          logo: true,
          search: true,
          reportItem: true,
          contactItem: true,
          registerButton: false,
          loginButton: false,
          logout: true
        }
      }
    },
    setBreakPoint(state, action) {
      if (action.payload) {
        state.breakPoint = action.payload;
      }
    },
    openModal(state, action) {
      if (action.payload) {
        state.modal.status = true;
        state.modal.content = action.payload;
      }
    },
    closeModal(state) {
      state.modal.status = false;
      state.modal.content = null;
    }
  }
})

export const { setPageClass, setUpHeader, setSideMenu, setBackButton, setBreakPoint, openModal, closeModal } = uiSlice.actions

export default uiSlice.reducer


