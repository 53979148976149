import { api } from "./Api"

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query(body) {
        return {
          url: `jwt-auth/v1/token`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: ['User'],
    }),
    validateToken: build.mutation({
      query() {
        return {
          url: `jwt-auth/v1/token/validate`,
          method: 'POST'
        }
      }
    }),
    register: build.mutation({
      query(body) {
        return {
          url: `api/v1/account/register`,
          method: 'POST',
          body
        }
      }
    }),
    requestPasswordReset: build.mutation({
      query(body) {
        return {
          url: `api/v1/account/request-password-reset`,
          method: 'POST',
          body
        }
      }
    }),
    processPasswordReset: build.mutation({
      query(body) {
        return {
          url: `api/v1/account/process-password-reset`,
          method: 'POST',
          body
        }
      }
    }),
  })
})

export const { useLoginMutation, useValidateTokenMutation, useRegisterMutation, useRequestPasswordResetMutation, useProcessPasswordResetMutation } = extendedApi
