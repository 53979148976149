import React from "react";
import cx from "classnames";
import classes from "components/layout/navigation/SideNavigation.module.scss";


const SkeletonSideNavigation = ({ headerStatus, className }) => {

    const _classes = cx(classes.skeleton, className, {
        [classes['open']]: headerStatus === true,
    });

    return (
        <div className={_classes}>
            <ul className={classes.navigation}>
                <li className={classes["nav-item"]} >
                    <div className={classes["menu-group"]}>
                        <div className={classes["menu-title"]}>
                            <div className={classes['skeleton-title']}><span></span><div className={classes.text}></div></div>
                        </div>
                        <div className={classes["menu-title"]}>
                            <div className={classes['skeleton-title']}><span></span><div className={classes.text}></div></div>
                        </div>
                        <div className={classes["menu-title"]}>
                            <div className={classes['skeleton-title']}><span></span><div className={classes.text}></div></div>
                        </div>
                        <div className={classes["menu-title"]}>
                            <div className={classes['skeleton-title']}><span></span><div className={classes.text}></div></div>
                        </div>
                        <div className={classes["menu-title"]}>
                            <div className={classes['skeleton-title']}><span></span><div className={classes.text}></div></div>
                        </div>
                        <div className={classes["menu-title"]}>
                            <div className={classes['skeleton-title']}><span></span><div className={classes.text}></div></div>
                        </div>
                        <div className={classes["menu-title"]}>
                            <div className={classes['skeleton-title']}><span></span><div className={classes.text}></div></div>
                        </div>
                        <div className={classes["menu-title"]}>
                            <div className={classes['skeleton-title']}><span></span><div className={classes.text}></div></div>
                        </div>
                        <div className={classes["menu-title"]}>
                            <div className={classes['skeleton-title']}><span></span><div className={classes.text}></div></div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default SkeletonSideNavigation;