import { urlBase64ToUint8Array } from 'utils/helpers';

import G from 'app/Globals';

const subscribeUserToPushNotifications = () => {
    const userToken = localStorage.getItem('wb_slade_token');

    if (!userToken) {
        //console.log('User token not found. Aborting subscription process.');
        return false; // Abort subscription process if user token is not found
    }

    if (!('serviceWorker' in navigator)) {
        // Service Worker isn't supported on this browser
        //console.log('no service worker');
        return;
    }

    if (!('PushManager' in window)) {
        // Push isn't supported on this browser
        //console.log('no push manager');
        return;
    }

    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
        // Notifications are not supported by this browser
        //console.log('no notifications');
        return;
    }

    if (Notification.permission === 'denied') {
        // Notifications are denied by the user
        //console.log('user denied');
        return;
    }

    navigator.serviceWorker.ready
        .then(registration => registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array('BIXQRn40K0i2dHP3eybey4OW78AWh47io0ICmO_uApccTwGTNTQQFKUUnKxuVnWWcesf6_uDX1X9l_QGKqWVtTw'),
        }))
        .then(subscription => {
            //console.log('Received push subscription: ', JSON.stringify(subscription));
            // Send the subscription details to the backend
            return fetch(`${G.API_URL}wb-push-notifications/v1/push/subscribe`, {
                method: 'POST',
                body: JSON.stringify(subscription),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                }
            });
        })
        .catch(e => {
            console.log(e);
        });
}

export { subscribeUserToPushNotifications };

