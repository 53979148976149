
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetAccountDataQuery } from "services/AccountApi";
import NavLink from "components/layout/navigation/Navlink";
import Link from "components/layout/navigation/Link";
import { logout } from "store/features/slice-auth";
import classes from "components/layout/Header.module.scss";
import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as Login } from "assets/icons/lock.svg";
import SideNavigation from "components/layout/navigation/SideNavigation"
import Button from "components/button/Button";
import Search from "modules/search/Search"

const Header = () => {

    const header = useSelector((state) => state.ui.header);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const { data } = useGetAccountDataQuery(undefined, {skip: !isAuthenticated} );
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const isVerified = isAuthenticated && data?.user?.roles.length > 0

    const openMenu = () => {
        setOpen(!isOpen)
        if (!isOpen) {
            document.querySelector('body').classList.add("menu-open-mobile");
        } else {
            document.querySelector('body').classList.remove("menu-open-mobile");
        }
    }

    return (
        <div className={classes.header + " " + (isOpen ? classes.scroll : classes.noscroll)}>
            <div className={classes.inner}>
                {header.logo ? <div className={classes.logo}>
                    <NavLink to="/">
                        <Logo />
                    </NavLink>
                </div> : null}
                <div className={classes.menu}>
                    {header.search && isVerified ? <Search isMenu /> : null}
                    {header.reportItem && isVerified ? <NavLink to={"/report-an-issue"} className={classes.link} active={classes.active}>Report an issue</NavLink> : null}
                    {header.contactItem ? <NavLink to={"/contact"} className={classes.link} active={classes.active}>Contact</NavLink> : null}
                    {header.logout ? isAuthenticated ? <Link to={"/login"} className={classes.login} onClick={() => dispatch(logout())}><Login />Log Out</Link> : <NavLink className={classes.login} to="/login"><Login />Log in</NavLink> : null}
                    {header.registerButton ? <Link className={classes.buttonLink} to={"/register"}><Button className={classes.headerButton} color={'blue'} text={"Register"} size={"small"} /></Link> : null}
                    {header.loginButton ? <Link className={classes.buttonLink} to={"/login"}><Button className={classes.headerButton} color={'blue'} outline text={"Login"} size={"small"} /></Link> : null}
                </div>
                <div className={classes.hamburger + " " + (isOpen ? classes.active : '')} onClick={() => openMenu()}>
                    <div className={classes.line}></div>
                    <div className={classes.line}></div>
                    <div className={classes.line}></div>
                </div>
            </div>
            <div className={classes['mobile-menu'] + " " + (isOpen ? classes.active : '')}>
                <SideNavigation closeMenu={openMenu} headerStatus={isOpen} mobile />
            </div>
        </div>
    )
}

export default Header;