import { useSelector } from "react-redux";
import cx from "classnames";
import classes from "./Toasts.module.scss";
import Alert from "components/alert/Alert";


const Toasts = (props) => {

    const toasts = useSelector((state) => state.alerts.toasts);
    const _classes = cx(classes.wrapper, props.className, {});

    if (toasts?.length > 0) {
        return (
            <div className={_classes}>
                {toasts.map((toast, index) => (
                    <Alert {...toast} key={index}   />
                ))}
            </div>
        )
    } else {
        return null;
    }

    
}

export default Toasts; 