
import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { api } from "services/Api"
import { useValidateTokenMutation } from "services/LoginApi";
import { useGetAccountRolesQuery, useGetAccountDataQuery } from "services/AccountApi";
import { useLocation } from "react-router-dom";
import Header from "components/layout/Header";
import Main from "components/layout/Main";
import Footer from "components/layout/Footer"
import Toasts from "modules/toasts/Toasts";
import ApiErrorHandler from "components/api_error_handler/ApiErrorHandler";



const Layout = ({ children }) => {

    const dispatch = useDispatch();
    const header = useSelector((state) => state.ui.header);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const { data: rolesData, refetch, error: rolesError } = useGetAccountRolesQuery(undefined, { skip: !isAuthenticated} );
    const { data: accountData, error: accountError } = useGetAccountDataQuery(undefined, {
        refetchOnFocus: window.navigator.onLine ? true : false,
        refetchOnReconnect : true,
        skip: !isAuthenticated
    } );
    const didUseEffect = useRef(false);
    const [validateToken, { error: validateTokenError }] = useValidateTokenMutation();
    const location = useLocation();
    const error = rolesError || accountError || validateTokenError;

    useEffect(() => {
        if (didUseEffect.current) {
            return
        }
        didUseEffect.current = true
    }, []);

    useEffect(() => {
        setupListeners(dispatch);
    }, [dispatch])

    useEffect(() => {
        // validate if token is still valid + Request Current user role
        const fetchData = async () => {
            await validateToken().unwrap();
        }
        //always check user on page change if app is online
        if (isAuthenticated && window.navigator.onLine) {
            fetchData();
            refetch();
        }

    }, [isAuthenticated, location.pathname, validateToken, refetch]);

    useEffect(() => {
        
        //check if cached role is similar to DB role

        if(rolesData && accountData){
            if(rolesData?.roles && accountData?.user?.roles){
                if(rolesData.roles.length === 0 &&  accountData?.user?.roles.length > 0){
                    dispatch(api.util.invalidateTags(['User']))
                }
                else{
                    if(rolesData.roles.some(item => !accountData.user.roles.includes(item))){
                        dispatch(api.util.invalidateTags(['User']))
                    }
                }
            }
        }

    }, [rolesData, accountData, dispatch]);

    // useEffect(() => {
    //     if(isProduction){
    //         fetch('/meta.json')
    //             .then((response) => response.json())
    //             .then((meta) => {
    //                 const latestVersion = meta.version || APP_DATA.version;
    //                 const currentVersion = APP_DATA.version;
    //                 if(latestVersion !== currentVersion){
    //                     window.location.reload(true);
    //                 }
    //         });
    //     }
    // }, [location.pathname, isProduction])


    if (error) return <ApiErrorHandler error={error} />

    return (
        <>
            {header.show ? <Header /> : null}
            <Toasts />
            <Main>{children}</Main>
            <Footer />
        </>
    )
}

Layout.displayName = 'Layout';
export default Layout;