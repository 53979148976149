import { createSlice } from "@reduxjs/toolkit";
import { api } from "services/Api"
import { subscribeUserToPushNotifications } from 'services/Push';


const initialState = {
  isAuthenticated: localStorage.getItem("wb_slade_token") ? true : false
};

export const logout = () => {
  return (dispatch) => {
    dispatch(logoutState());
    dispatch(api.util.resetApiState());
  }
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginState(state, action) {
      localStorage.setItem("wb_slade_token", action.payload);
      state.isAuthenticated = true;
      subscribeUserToPushNotifications();
    },
    logoutState(state) {
      localStorage.removeItem("wb_slade_token");
      state.isAuthenticated = false;
    }
  }
});

export const { loginState, logoutState } = authSlice.actions;

export default authSlice.reducer;
