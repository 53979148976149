import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PrivateOutlet() {

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();

  if(isAuthenticated){
    return <Outlet />
  }
  else{
    return <Navigate to={"/login"} replace={true} state={{from: location.pathname}} />
  }
}
