import React from "react";
import cx from "classnames";
import classes from "./LoadingSpinner.module.scss";

const LoadingSpinner = ({ className }) => {
    const _classes = cx(classes.loading, className);

    return (
        <div className={_classes}>
            <div className={classes.spinner}>
                Loading...
            </div>
        </div>
    )
}

export default LoadingSpinner;