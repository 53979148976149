import React from "react";
import NavLink from "components/layout/navigation/Navlink";
import { useGetStabilityListQuery } from "services/StabilityApi";
import { useGetEducationListQuery } from "services/EducationApi";
import { useGetClinicalTrialsListQuery } from "services/ClinicalTrialsApi";
import { useGetClinicalResourcesListQuery } from "services/ClinicalResourcesApi";
import classes from "./SubMenuItem.module.scss"


const MenuItem = ({ item: parentItem, closeMenu, active }) => {

  const { data: stability_data, error: stability_error, isLoading: stability_isLoading } = useGetStabilityListQuery(undefined, { skip: parentItem.title !== 'Stability' });
  const { data: education_data, error: education_error, isLoading: education_isLoading } = useGetEducationListQuery(undefined, { skip: parentItem.title !== 'Education' });
  const { data: clinical_trials_data, error: clinical_trials_error, isLoading: clinical_trials_isLoading } = useGetClinicalTrialsListQuery(undefined, { skip: parentItem.title !== 'Clinical Trials' });
  const { data: clinical_resources_data, error: clinical_resources_error, isLoading: clinical_resources_isLoading } = useGetClinicalResourcesListQuery(undefined, { skip: parentItem.title !== 'Clinical Resources' });
  const isLoading = stability_isLoading || education_isLoading || clinical_trials_isLoading || clinical_resources_isLoading;
  const isError = stability_error || education_error || clinical_trials_error || clinical_resources_error;
  const data = stability_data || education_data || clinical_trials_data || clinical_resources_data;

  const handleCloseMenu = () => {
    if (typeof closeMenu === 'function') {
      closeMenu();
    }
  }

  return (
    <div className={classes.submenu + " " + (active === parentItem?.url ? classes['active'] : null)}>
      {isLoading ? <div className={classes['submenu-item'] + " " + classes['loading-text']}>Loading...</div> : null}
      {isError ? 'Error' : null}
      {data?.response ?
        <>
          <NavLink
            to={`${parentItem.url}`}
            end
            className={classes['submenu-item']}
            active={classes.active}
            onClick={() => handleCloseMenu()}
          >
            Overview
          </NavLink>
          {
            data.response.map((item, index) => (
              <NavLink
                key={index}
                to={`${item.path}`}
                className={classes['submenu-item']}
                active={classes.active}
                onClick={() => handleCloseMenu()}
              >
                {item.title}
              </NavLink>
            ))
          }
        </>
        : null
      }
    </div>
  )
};

export default MenuItem;