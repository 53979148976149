import React from "react";
import { Link } from "react-router-dom";
import G from "app/Globals";

const CustomLink = (props, ref) => {

    const handleClick = (e) => {
        if (typeof props.scroll === 'undefined' || (typeof props.scroll === 'number' && props.scroll === 1)) {
            G.resetPageScroll();
        }
        if (typeof props.onClick === 'function') {
            props.onClick(e);
        }
    }

    if(props.to?.includes('http://') || props.to?.includes('https://') || props.to?.includes('mailto:')){
        return (
            <a className={props.className} href={props.to} target={props.target} rel="noopener noreferrer">{props.children}</a>
        )
    }
    return (
        <Link
            ref={ref}
            {...props}
            onClick={(e) => handleClick(e)}
        >
            {props.children}
        </Link>
    );
    
    
};

export default React.forwardRef(CustomLink);