import { Buffer } from 'buffer';
import FILES_EXTENSIONS_TYPES from "data/files_extensions.json";
import { useState, useEffect } from 'react';

const globals = {};

globals.DEFAULT_LANG = "en";
globals.APP_URL_STAGING = "https://stg-sladeconnect.icontest.com.au";
globals.APP_URL = process.env.REACT_APP_APP_URL || "http://localhost:3000";
globals.BE_URL = process.env.REACT_APP_BE_URL || "http://slade.be.local"; //use local url on dev mode
globals.API_URL = `${globals.BE_URL}/wp-json/`;


globals.NO_TOKEN_ENDPOINTS = ['requestPasswordReset', 'submitGravityForm'];

globals.GF_CONSUMER_KEY = 'ck_26387d5d50fefa7947ec74201f69e2ed57b786b4';
globals.GF_CONSUMER_SECRET = 'cs_a8e897a6f86459b7c30ba04f7928d536da2ea1cf';


globals.checkFileType = (type, allowedExtensions) => {
    const allowedExtensions_arr = allowedExtensions.split(',').filter(el => el).map(str => str.replace(/\s+/g, ''));
    const allowedTypes = allowedExtensions_arr.reduce((types, extension) => {
        const items = FILES_EXTENSIONS_TYPES.filter(el => el.extension === extension);
        items.forEach(item => {
            if (item) {
                types.push(item.type);
            }
        });
        return types;
    }, []);

    if (allowedExtensions_arr.length !== allowedTypes.length) {
        console.log("Dev Error: Add File Type To files_extensions.json");
    }

    if (allowedTypes.includes(type)) {
        return true;
    } else {
        return false;
    }
}


globals.encode = (str) => {
    var encoded = "";
    str = Buffer.from(str).toString('base64');
    str = Buffer.from(str).toString('base64');
    for (let i = 0; i < str.length; i++) {
        var a = str.charCodeAt(i);
        var b = a ^ 10; // bitwise XOR with any number, e.g. 123
        encoded = encoded + String.fromCharCode(b);
    }
    encoded = Buffer.from(encoded).toString('base64');
    return encoded;
}


globals.cacheImages = async(srcArr, callback) => {
    const promises = await srcArr.map((src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve();
            img.onerror = reject();
        })
    })

    await Promise.all(promises);
    if(typeof callback === 'function'){
        callback();
    }
}

globals.resetPageScroll = () => {
    window.scrollTo(0, 0);
    let mainDiv = document.querySelector('main');
    mainDiv.scrollTo(0, 0);
}

globals.completionTimeLabel = (cpd) => {
    return cpd.time ? cpd.time?.time + ' ' + cpd.time?.unit : null
}

globals.cpdLabel = (cpd) => {
    let text = null
    if (cpd && cpd.type) {
        switch (cpd.type) {
            case 'Self-directed': text = `Self-directed`
                break;
            case 'Accredited':
                // Decide text based on cpd.display
                if (cpd.display === 'time') {
                    // Use globals.completionTimeLabel for time formatting
                    const timeText = globals.completionTimeLabel(cpd);
                    text = `${cpd.category ? 'Category ' + cpd.category : 'Accredited'} ${timeText ? '(' + timeText + ')' : ''}`;
                } else {
                    // Default to points
                    text = `${cpd.category ? 'Category ' + cpd.category : 'Accredited'} ${cpd.points ? '(' + cpd.points + ' points)' : ''}`;
                }
                break;
            default: text = null
        }
        return text;
    }
    else return null;
}

export default globals;


export const useIntersection = (element, rootMargin = '0px') => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Check for IntersectionObserver support
        if (!window.IntersectionObserver) {
            console.warn('IntersectionObserver is not supported by your browser.');
            setIsVisible(true); // Consider the element as always visible if IntersectionObserver is not supported
            return;
        }

        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { rootMargin }
        );

        const currentElement = element.current;

        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [element, rootMargin]); // Including element and rootMargin in the dependency array

    return isVisible;
};
