import { api } from "./Api"

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getClinicalResourcesList: build.query({
      query() {
        return {
          url: `api/v1/clinical-resources`
        }
      }
    }),
    getClinicalResourceSingle: build.query({
      query(slug) {
        return {
          url: `api/v1/clinical-resource/${slug}`
        }
      }
    }),
    getClinicalResourceChildren: build.query({
      query(parent) {
        return {
          url: `api/v1/clinical-resource-children/${parent}`
        }
      }
    }),
  }),
  overrideExisting: false
})

export const { useGetClinicalResourcesListQuery, useGetClinicalResourceSingleQuery, useGetClinicalResourceChildrenQuery } = extendedApi