import React, {useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import cx from "classnames";
import { useSelector } from "react-redux";
import { useGetAccountDataQuery } from "services/AccountApi";
import Button from "components/button/Button";
import Link from "components/layout/navigation/Link"
import { ReactComponent as Stability } from "assets/icons/information.svg";
import { ReactComponent as Education } from "assets/icons/book.svg";
import { ReactComponent as Clinical } from "assets/icons/clinical-requests.svg";
import { ReactComponent as Updates } from "assets/icons/important-updates.svg";
import classes from "./Search.module.scss";

const Search = (props) => {

    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const { data: accountData, isLoading } = useGetAccountDataQuery(undefined, { skip: !isAuthenticated });

    const [searchActive, setSearchActive] = useState(false);
    const menuParentRef = useRef()

    const [searchQuery, setSearchQuery] = useState('');
    const _classes = cx(classes['search-wrapper'], props.className, {});

    const handleOnFocus = (e) => {
        setSearchActive(true)
    }

    const handleItemClick = () => {
        setSearchActive(false)
    }

    // const handleOnBlur = (e) => {
    //     setTimeout(() => {
    //         setSearchActive(false)
    //     }, 100);
    // }

    const handleChange = event => {
        if(event.target.value?.trim() === ''){
            setSearchActive(true)
        }
        else{
            setSearchActive(false)
        }
        setSearchQuery(event.target.value);
    }

    const handleFormSubmit = event => {
        event.preventDefault();
        navigate('/search/' + searchQuery)
        setSearchActive(false)
    }

    useEffect(() => {
        const checkIfClickedOutside = e => {
          // If the menu is open and the clicked target is not within the menu,
          // then close the menu
          if (searchActive && menuParentRef.current && !menuParentRef.current.contains(e.target)) {
            setSearchActive(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
    
        return () => {
          // Cleanup the event listener
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [searchActive])


    return (

        <div className={_classes} ref={menuParentRef}>
            <div className={classes.search+ " " + (props.isMenu ? classes.menu : '')}>
                <form className={classes.form} onSubmit={handleFormSubmit}>
                    <input placeholder={'Search'} onFocus={handleOnFocus} value={searchQuery} onChange={handleChange} className={classes.input} />
                    <Button type={"submit"} color={"system-yellow"} className={classes.button} icon={'search'} size={'small'}></Button>
                    {searchActive && props.isMenu ? 
                        <div className={classes.items}>
                            {isLoading ? <div className={classes.item}><span>Loading...</span></div> : null}
                            {accountData?.user?.capabilities.includes('wb_read_education') ? <Link onClick={handleItemClick} to={"/education"} className={classes.item}><span><Education /></span>Education</Link> : null }
                            {accountData?.user?.capabilities.includes('wb_read_stability_matrix') ? <Link onClick={handleItemClick} to={"/stability"} className={classes.item}><span><Stability /></span>Stability</Link> : null}
                            {accountData?.user?.capabilities.includes('wb_read_clinical_requests') ? <Link onClick={handleItemClick} to={"/clinical-requests"} className={classes.item}><span><Clinical /></span>Clinical Requests</Link> : null}
                            {accountData?.user?.capabilities.includes('wb_read_blog') ? <Link onClick={handleItemClick} to={"/important-updates"} className={classes.item}><span><Updates /></span>Important Updates</Link> : null}
                        </div>
                    : null}
                </form>
            </div>
        </div>
    )
}

export default Search; 