import { api } from "./Api"

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getClinicalTrialsList: build.query({
      query() {
        return {
          url: `api/v1/clinical-trials`
        }
      }
    }),
    getClinicalTrialSingle: build.query({
      query(slug) {
        return {
          url: `api/v1/clinical-trial/${slug}`
        }
      }
    }),
  }),
  overrideExisting: false
})

export const { useGetClinicalTrialsListQuery, useGetClinicalTrialSingleQuery } = extendedApi