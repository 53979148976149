import React from "react";
import cx from "classnames";
import Button from "components/button/Button";
import classes from "./Section.module.scss";

const Section = ({
    title,
    description,
    children,
    padding,
    fullHeight,
    classInner,
    className,
    paddingTop,
    paddingBottom,
    flush,
    editMode,
    removeDashBoardItem
}) => {
    const _classes = cx(classes.section, className, {
        [classes['flush']]: typeof flush !== 'undefined' && flush !== '',
        [classes['full-height']]: fullHeight,
        [classes['padding-' + padding]]: typeof padding !== 'undefined' && padding !== '',
        [classes['padding-bottom-' + paddingBottom]]: typeof paddingBottom !== 'undefined' && paddingBottom !== '',
        [classes['padding-top-' + paddingTop]]: typeof paddingTop !== 'undefined' && paddingTop !== '',
        [classes['edit']]: typeof editMode === 'boolean' && editMode,
    });
    const _classes_inner = cx(classes.inner, classInner)

    return (
        <section className={_classes}>
            <div className={_classes_inner}>
                {title ? <h2 className={classes.title}>{title}</h2> : null}
                {description ? <h4 className={classes.description}>{description}</h4> : null}
                {children}
            </div>
            {editMode && removeDashBoardItem ? 
                <Button
                    className={classes['remove-btn']}
                    text={"Remove"}
                    icon={"close"}
                    color={"black"}
                    size={"extra-small"}
                    Clicked={() => removeDashBoardItem()}

                />
            : null}
        </section>
    )
}

export default Section;