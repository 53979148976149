import React from "react";
import { useDispatch } from "react-redux";
import { removeToast } from "store/features/slice-alerts";
import cx from "classnames";
import classes from "./Alert.module.scss";
import Button from "components/button/Button"
import { ReactComponent as CloseIcon } from "assets/close.svg"
import { ReactComponent as ErrorIcon } from "assets/error.svg"
import { ReactComponent as InformationIcon } from "assets/information.svg"
import { ReactComponent as SuccessIcon } from "assets/success.svg"
import { ReactComponent as WarningIcon } from "assets/warning.svg"

const Alert = ({ id, name, title, text, className, size, type, animation, variant }) => {

    const _classes = cx(classes.alert, className, {
        [classes[size]]: typeof size !== 'undefined' && size !== '',
        [classes[type]]: typeof type !== 'undefined' && type !== '',
        [classes[animation]]: typeof animation !== 'undefined' && animation !== '',
        [classes[variant]]: typeof variant !== 'undefined' && variant !== ''
    });

    const dispatch = useDispatch();

    const variantColor = (variant) => {
        if (variant === "information") {
            return 'blue';
        } else if (variant === "warning") {
            return 'yellow';
        } else if (variant === "error") {
            return 'red';
        } else if (variant === "success") {
            return 'green';
        }
    }

    return (
        <div
            className={_classes}
            name={name}
        >
            <CloseIcon className={classes.close} onClick={() => dispatch(removeToast(id))} />
            <div className={classes.alertContent}>
                {title ?
                    <span className={classes.title}>
                        {
                            variant === "information" ? <InformationIcon /> :
                                variant === "warning" ? <WarningIcon /> :
                                    variant === "error" ? <ErrorIcon /> :
                                        variant === "success" ? <SuccessIcon /> :
                                            null
                        }
                        {title}
                    </span>
                    : null}
                {text ? <span className={classes.text}>{text}</span> : null}
            </div>
            {type === "option" ?
                <div className={classes.buttonWrapper}>
                    <Button color={'system-' + variantColor(variant)} size={'alert'} fullWidth text={"Yes"} />
                    <Button color={'system-' + variantColor((variant))} size={'alert'} fullWidth type={"outline"} text={"No"} />
                </div> :
                type === "confirm" ?
                    <Button color={'system-' + variantColor((variant))} size={'alert'} text={"OK"} />
                    : null}
        </div>
    )
};

export default Alert;