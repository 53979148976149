import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import G from "app/Globals";

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: G.API_URL,
    prepareHeaders: (headers, { endpoint }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      let token = localStorage.getItem('wb_slade_token');
      if (token && !G.NO_TOKEN_ENDPOINTS.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers
    },
  }),
  reducerPath: 'api',
  endpoints: () => ({}),
});
