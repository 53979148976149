import { useSelector } from "react-redux";
import { useGetAccountDataQuery } from "services/AccountApi";
import classes from "./Main.module.scss";
import SideMenu from "components/layout/navigation/SideMenu";

const Main = ({ children }) => {

  const showSideMenu = useSelector((state) => state.ui.showSideMenu);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { data, isLoading } = useGetAccountDataQuery(undefined, { skip: !isAuthenticated });
  
  if(isLoading) return null;
  return (
    <>
      <main className={classes.wrapper}>
         {showSideMenu && isAuthenticated && data?.user?.roles?.length > 0 ?
          <div className={classes.inner}>
            <div className={classes.sidebar}><SideMenu /></div>
            <div className={classes.content}>
              {children}
            </div>
          </div>
          : children
        }

      </main>
    </>
  );
}

Main.displayName = 'Main';
export default Main;
