import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "store/features/slice-auth";
import cx from "classnames";
import classes from "./ApiErrorHandler.module.scss";
import LostConnection from "modules/lost_connection/LostConnection";

const ApiErrorHandler = ({ error, className }) => {
    
    const _classes = cx(classes.loading, className);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        if (error?.status === 403) {
            dispatch(logout());
        }
        if(error?.status === 404){
            navigate('/page-not-found');
        }
    }, [error, dispatch, navigate])

    if (error?.status === 'FETCH_ERROR') {
        return <LostConnection error={error} />
    } 
    else if(error?.status === 401){
        //do nothing, just return nothing unless we wanna show a non-access message to end users
        return <></>
    }
    else {
        return (
            <div className={_classes}>
                <div className={classes.spinner}>
                    Error in Module
                </div>
            </div>
        )

    }
}

export default ApiErrorHandler;