import { api } from "./Api"

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStabilityList: build.query({
      query() {
        return {
          url: `api/v1/stability`
        }
      }
    }),
    getStabilitySingle: build.query({
      query(slug) {
          return {
              url: `api/v1/stability/${slug}`
          }
      }
  }),
  }),
  overrideExisting: false
})

export const { useGetStabilityListQuery, useGetStabilitySingleQuery } = extendedApi