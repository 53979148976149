import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetAccountDataQuery } from "services/AccountApi";


export default function CapabilityOutlet({capability}) {

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { data } = useGetAccountDataQuery(undefined, {skip: !isAuthenticated} );

  if(data?.user?.capabilities){
      if(data.user.capabilities.includes(capability)){
        return (<Outlet />)
      }
      else{
        return (<Navigate to={"/"} replace={true} />)
      }
  }
}
