import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PublicOutlet() {

  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  if(isAuthenticated){
    return <Navigate replace={true} to={location.state?.from ? location.state.from  :"/"} />
  }
  else{
    return <Outlet />
  }
  
}
