import { configureStore } from "@reduxjs/toolkit";
import { api } from "services/Api";
import settingsReducer from "./features/slice-settings";
import uiReducer from "./features/slice-ui";
import authReducer from "./features/slice-auth";
import alertsReducer from "./features/slice-alerts";

export default configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    settings: settingsReducer,
    ui: uiReducer,
    alerts: alertsReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false, // Disable serializable checks
  }).concat(api.middleware)
});