
import React, { useState, useRef, useEffect } from "react";
import classes from "components/layout/Footer.module.scss";
import NavLink from "./navigation/Navlink";
import { ReactComponent as Chevron } from "assets/chevron-right.svg";
import APP_DATA from "../../../package.json";

const Footer = () => {

    const [isOpen, setOpen] = useState(false);
    const menuParentRef = useRef();

    useEffect(() => {
        const checkIfClickedOutside = e => {
          // If the menu is open and the clicked target is not within the menu,
          // then close the menu
          if (isOpen && menuParentRef.current && !menuParentRef.current.contains(e.target)) {
            setOpen(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
    
        return () => {
          // Cleanup the event listener
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [isOpen])

      const closeMenu = () => {
        setOpen(false);
      }

    return (
        <div ref={menuParentRef} className={classes.footer + " " + (isOpen ? classes.active: '')}>
            <div className={classes.inner}>
                <div className={classes.pageWrapper}>
                    <ul className={classes.pages + " " + (isOpen ? classes.active : '')}>
                        <li>
                            <NavLink onClick={closeMenu} to="/about"><div>About Slade</div></NavLink>
                        </li>
                        <li>
                            <NavLink onClick={closeMenu} to="/privacy-policy"><div>Privacy Policy</div></NavLink>
                        </li>
                        <li>
                            <NavLink onClick={closeMenu} to="/disclaimer"><div>Disclaimer</div></NavLink>
                        </li>
                    </ul>
                    <div className={classes.mobile} onBlur={() => setOpen(false)} onClick={() => setOpen(!isOpen)}>
                        <div>Information <Chevron /></div>
                    </div>
                </div>
                <div className={classes.version}>{`v${APP_DATA.version}`}</div>
            </div>
        </div>
    )
}

Footer.displayName = 'Footer';
export default Footer;