import { createSlice } from "@reduxjs/toolkit";
import globals from "../../app/Globals";

const initialState = {
  language: globals.DEFAULT_LANG,
  history: null
}


const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setAppLanguage(state, action) {
      state.language = action.payload;
    },
    setReduxHistory(state, action) {
      state.history = action.payload;
    }
  }
})

export const { setAppLanguage, setReduxHistory } = settingsSlice.actions

export default settingsSlice.reducer


