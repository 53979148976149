import cx from "classnames";
import classes from "./LostConnection.module.scss";
import Button from "components/button/Button";
import Section from "components/section/Section"
import { useNavigate } from 'react-router-dom';


const LostConnection = (props) => {

    const _classes = cx(classes.wrapper, props.className, {
        [classes['full-width']]: props.fullWidth,
        [classes['loading']]: typeof props.loading !== 'undefined' && props.loading !== '',
        [classes[props.variant]]: typeof props.variant !== 'undefined' && props.variant !== '',
    });

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    return (
        <Section>
            <div className={_classes}>
                <div className={classes.content}>
                    <h2 className={classes.title}>Lost Connection to Server</h2>
                    <div className={classes.text}>Your connection may have been interrupted, or the session was left idle for too long. Please reload the page.</div>
                    <div className={classes.form}>
                        <Button color={"blue"} Clicked={() => refreshPage()} className={classes.button} fullwidth text={"Reload Page"} size={'small'} />
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default LostConnection; 