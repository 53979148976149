import React from "react";
import { NavLink } from "react-router-dom";
import G from "app/Globals";

const CustomNavLink = (props) => {

    const handleClick = (e) => {
        if (typeof props.scroll === 'undefined' || (typeof props.scroll === 'number' && props.scroll === 1)) {
            G.resetPageScroll();
        }
        if (typeof props.onClick === 'function') {
            props.onClick(e);
        }
    }

    return (
        <NavLink
            {...props}
            className={({ isActive }) =>
                 props.className+ (isActive ? " "+props.active : "")
            }
            onClick={(e) => handleClick(e)}
        >
            {props.children}
        </NavLink>
    );
};

export default CustomNavLink;