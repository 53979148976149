import React, { useState } from "react";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import { useGetAccountDataQuery } from "services/AccountApi";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "store/features/slice-auth";
import { menuItems } from "components/layout/navigation/menuItems";
import SubMenuItem from "components/layout/navigation/SubMenuItem";
import NavLink from "components/layout/navigation/Navlink";
import { ReactComponent as Home } from "assets/icons/home.svg";
import { ReactComponent as Stability } from "assets/icons/information.svg";
import { ReactComponent as Clinical } from "assets/icons/clinical-requests.svg";
import { ReactComponent as Pricing } from "assets/icons/pricing-requests.svg";
import { ReactComponent as Updates } from "assets/icons/important-updates.svg";
import { ReactComponent as Team } from "assets/icons/meet-the-team.svg";
import { ReactComponent as Catalogue } from "assets/icons/product-catalogue.svg";
import { ReactComponent as Links } from "assets/icons/linkout.svg";
import { ReactComponent as Issue } from "assets/icons/report.svg";
import { ReactComponent as Login } from "assets/icons/lock.svg";
import { ReactComponent as Contact } from "assets/icons/phone.svg";
import { ReactComponent as Account } from "assets/icons/user.svg";
import { ReactComponent as Book } from "assets/icons/book.svg";
import { ReactComponent as CpdLog } from "assets/icons/cpd-log.svg";
import { ReactComponent as ClinicalTrials } from "assets/icons/clinical-trials.svg";
import { ReactComponent as ClinicalResources } from "assets/icons/clinical-resources.svg";
import Skeleton from "./Skeleton";
import classes from "components/layout/navigation/SideNavigation.module.scss";


const SideNavigation = ({ closeMenu, is_loading, headerStatus, mobile, className }) => {

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const { data: accountData } = useGetAccountDataQuery(undefined, { skip: !isAuthenticated });

    const dispatch = useDispatch();
    const location = useLocation();
    const [subMenuActive, setSubmenuActive] = useState(false)
    const _classes = cx(classes.header, className, {
        [classes['open']]: headerStatus === true
    });


    const determineIcon = (title) => {
        if (title === 'Home') {
            return <Home />
        }
        if (title === 'Stability') {
            return <Stability />
        }
        if (title === 'Education') {
            return <Book />
        }
        if (title === 'CPD Log') {
            return <CpdLog />
        }
        if (title === 'Clinical Resources') {
            return <ClinicalResources />
        }
        if (title === 'Clinical Requests') {
            return <Clinical />
        }
        if (title === 'Clinical Trials') {
            return <ClinicalTrials />
        }
        if (title === 'Pricing Requests') {
            return <Pricing />
        }
        if (title === 'Important updates') {
            return <Updates />
        }
        if (title === 'Meet the team') {
            return <Team />
        }
        if (title === 'Product Catalogue') {
            return <Catalogue />
        }
        if (title === 'Quick Links') {
            return <Links />
        }
        if (title === 'Report an issue') {
            return <Issue />
        }
    }



    const handleMenuItemClick = (e, item) => {
        setSubmenuActive(false);
        if (item.has_submenu) {
            e.preventDefault();
            setSubmenuActive(item.url);
        } else {
            handleCloseMenu();
        }
    }

    const handleCloseMenu = () => {
        if (typeof closeMenu === 'function') {
            closeMenu();
        }

    }
    if (is_loading) return <Skeleton className={_classes} />
    return (
        <div className={_classes}>
            <ul className={classes.navigation}>
                {
                    accountData?.user?.capabilities
                        ? menuItems.map((item, index) => {
                            const is_active_item = location.pathname === item.url || (item.url !== '/' && location.pathname.includes(item.url + '/'));
                            const active_submenu = subMenuActive || (location.pathname !== '/' && (location.pathname === item.url || location.pathname.includes(item.url + '/')) ? item.url : null);
                            if (accountData.user.capabilities.includes(item.capability)) {
                                return (
                                    <li className={classes["nav-item"] + " " + (is_active_item ? classes.active : '') + " " + (subMenuActive === item.url ? classes.menuOpen : '')} key={index} >
                                        <div className={classes["menu-group"]}>
                                            <NavLink
                                                onClick={(e) => handleMenuItemClick(e, item)}
                                                to={item.url}
                                                className={classes["menu-title"]}
                                                scroll={item.has_submenu ? 0 : 1}
                                            >
                                                <span>{determineIcon(item.title)}</span>
                                                <div>{item.title}</div>
                                            </NavLink>
                                            {item.has_submenu ? <SubMenuItem closeMenu={closeMenu} active={active_submenu} item={item} /> : null}
                                        </div>
                                    </li>
                                )
                            }
                            else return null;

                        })
                        : null
                }
            </ul>

            {
                mobile ?
                    <div className={classes['additional-menu']}>
                        {!isAuthenticated ?
                            <ul className={classes.navigation}>
                                <li className={classes["nav-item"]}>
                                    <div className={classes["menu-group"]}>
                                        <NavLink className={classes['item-link']} onClick={() => handleCloseMenu()} active={classes.active} to="/login" end><div className={classes["menu-title"]}><span><Login /></span>Login</div></NavLink>
                                    </div>
                                </li>
                                <li className={classes["nav-item"]}>
                                    <div className={classes["menu-group"]}>
                                        <NavLink className={classes['item-link']} onClick={() => handleCloseMenu()} active={classes.active} to="/register" end><div className={classes["menu-title"]}><span><Team /></span>Register</div></NavLink>
                                    </div>
                                </li>
                            </ul>
                            : null}
                        <ul className={classes.navigation}>
                            <li className={classes["nav-item"]}>
                                <div className={classes["menu-group"]}>
                                    <NavLink className={classes['item-link']} onClick={() => handleCloseMenu()} active={classes.active} to="/contact" end><div className={classes["menu-title"]}><span><Contact /></span>Contact</div></NavLink>
                                </div>
                            </li>
                            {isAuthenticated ?
                                <li className={classes["nav-item"]}>
                                    <div className={classes["menu-group"]}>
                                        <NavLink className={classes['item-link']} onClick={() => handleCloseMenu()} active={classes.active} to="/search" end><div className={classes["menu-title"]}><span><Account /></span>Search</div></NavLink>
                                    </div>
                                </li>
                            : null}
                        </ul>
                        {isAuthenticated ?
                            <ul className={classes.navigation + " " + classes.account}>
                                <li className={classes["nav-item"]}>
                                    <div className={classes["menu-group"]}>
                                        <NavLink className={classes['item-link']} onClick={() => handleCloseMenu()} active={classes.active} to="/account" end><div className={classes["menu-title"]}><span><Account /></span>My Account</div></NavLink>
                                    </div>
                                </li>
                                <li className={classes["nav-item"]}>
                                    <div className={classes["menu-group"]}>
                                        {isAuthenticated ? <div className={classes.login + " " + classes["menu-title"]} onClick={() => dispatch(logout())}><span><Login /></span>Log Out</div> : <NavLink className={classes["menu-title"] + " " + classes.login} to="/login"><span><Login /></span>Log in</NavLink>}
                                    </div>
                                </li>
                            </ul>
                            : null}
                    </div>
                    : null
            }
        </div>
    )
}

export default SideNavigation;