export const menuItems = [
  {
    title: 'Home',
    url: '/',
    capability: 'read'
  },
  {
    title: 'Stability',
    url: '/stability',
    has_submenu: true,
    capability: 'wb_read_stability_matrix'
  },
  {
    title: 'Education',
    url: '/education',
    has_submenu: true,
    capability: 'wb_read_education'
  },
  {
    title: 'Clinical Resources',
    url: '/clinical-resources',
    has_submenu: true,
    capability: 'wb_read_clinical_resources'
  },
  {
    title: 'Clinical Requests',
    url: '/clinical-requests',
    capability: 'wb_read_clinical_requests'
  },
  {
    title: 'Clinical Trials',
    url: '/clinical-trials',
    has_submenu: true,
    capability: 'wb_read_clinical_trials'
  },
  {
    title: 'Pricing Requests',
    url: '/pricing-requests',
    capability: 'wb_read_pricing_requests'
  },
  {
    title: 'Important updates',
    url: '/important-updates',
    capability: 'wb_read_blog'
  },
  {
    title: 'Meet the team',
    url: '/meet-the-team',
    capability: 'wb_read_key_contacts'
  },
  {
    title: 'Product Catalogue',
    url: '/product-catalogue',
    capability: 'wb_read_product_catalogue'
  },
  {
    title: 'CPD Log',
    url: '/cpd-log',
    capability: 'wb_read_education'
  },
  {
    title: 'Quick Links',
    url: '/quick-links',
    capability: 'wb_read_quick_links'
  },
  {
    title: 'Report an issue',
    url: '/report-an-issue',
    capability: 'wb_read_report_issue'
  }
];