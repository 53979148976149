import React from "react";
import cx from "classnames";
import classes from "./Button.module.scss";
import { ReactComponent as PinIcon } from "assets/icons/pin.svg";
import { ReactComponent as Search } from "assets/icons/search.svg";
import { ReactComponent as External } from "assets/icons/linkout.svg";
import { ReactComponent as Customise } from "assets/icons/customise.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";


const Button = ({ type, name, text, icon, isDisabled, outline, color, size, fullWidth, className, Clicked }) => {
    const _classes = cx(classes.button, className, {
        [classes['full-width']]: fullWidth,
        [classes[size]]: typeof size !== 'undefined' && size !== '',
        [classes[type]]: typeof type !== 'undefined' && type !== '',
        [classes[color]]: typeof color !== 'undefined' && color !== '',
        [classes['outline']]: typeof outline === 'boolean' && outline
    });

    return (
        <button
            className={_classes}
            type={type ? type : 'button'}
            onClick={Clicked ? Clicked : null}
            disabled={isDisabled}
            name={name}
        >
            {icon === 'pin' ? <PinIcon /> : null}
            {icon === 'search' ? <Search /> : null}
            {icon === 'customise' ? <Customise /> : null}
            {icon === 'close' ? <CloseIcon /> : null}
            {text ? <span>{text}</span> : null}
            {icon === 'external' ? <div className={classes.external}><External /></div> : null}
        </button>
    )
};

export default Button;