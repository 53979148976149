import { api } from "./Api"

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccountData: build.query({
      query() {
        return {
          url: `api/v1/account`,
        }
      },
      providesTags: ['User'],
    }),
    getAccountRoles: build.query({
      query() {
        return {
          url: `api/v1/account/roles`,
        }
      }
    }),
    updateAccount: build.mutation({
      query(body) {
        return {
          url: `api/v1/account/update`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: ['User'],
    }),
    getPinnedClinicalRequests: build.query({
      query() {
        return {
          url: `api/v1/account/pinned-clinical-requests`,
        }
      },
      providesTags: ['User'],
    }),
    updatePinnedClinicalRequests: build.mutation({
      query(patch) {
        return {
          url: `api/v1/account/pinned-clinical-requests/update`,
          method: 'PATCH',
          body: patch
        }
      },
      onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getPinnedClinicalRequests', undefined, (draft) => {
            Object.assign(draft, patch)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
    }),
    getDashboard: build.query({
      query() {
        return {
          url: `api/v1/account/dashboard`,
        }
      },
      providesTags: ['User'],
    }),
    updateDashboard: build.mutation({
      query(patch) {
        return {
          url: `api/v1/account/dashboard/update`,
          method: 'PATCH',
          body: patch
        }
      },
      onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getDashboard', undefined, (draft) => {
            Object.assign(draft, patch)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
    }),

  }),
  overrideExisting: false,
})

export const { useGetAccountDataQuery, useGetAccountRolesQuery, useUpdateAccountMutation, useGetPinnedClinicalRequestsQuery, useUpdatePinnedClinicalRequestsMutation, useGetDashboardQuery, useUpdateDashboardMutation } = extendedApi