import { api } from "./Api"

const extendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEducationList: build.query({
            query() {
                return {
                    url: `api/v1/education`
                }
            }
        }),
        getEducationSingle: build.query({
            query(slug) {
                return {
                    url: `api/v1/education/${slug}`
                }
            }
        })
    }),
    overrideExisting: false,
})

export const { useGetEducationListQuery, useGetEducationSingleQuery } = extendedApi